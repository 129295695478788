import {
    FetchArgs,
    createApi,
    fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { useLocalStorage } from "src/hooks";
import { Path } from "src/utils";
import { logOut, setCredentials } from "./reducers/auth-slice";

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_TEST}`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const profile = useLocalStorage<Profile>("profile").getItem();
        const token = profile?.authenticationToken;
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (
    args: string | FetchArgs,
    api: any,
    extraOptions: Record<string, any>
) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { removeItem, getItem } = useLocalStorage<Profile>("profile");
    const profile = getItem();
    const refreshToken = profile?.refreshToken;

    let result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 401) {
        removeItem();
        const refreshResult = await baseQuery(
            {
                url: Path.Auth.refreshToken,
                method: "POST",
                body: {
                    refreshToken: refreshToken,
                },
            },
            api,
            extraOptions
        );
        if (refreshResult?.data) {
            const profile = api.getState().auth.profile;
            api.dispatch(setCredentials({ ...refreshResult.data, profile }));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logOut());
            document.location.replace("/");
        }
    }
    return result;
};

export const apiSlice = createApi({
    reducerPath: "authApi",
    tagTypes: [
        "profileData",
        "Phone",
        "Email",
        "Employee",
        "Position",
        "Banner",
        "Gallery",
        "News",
        "PageStructure",
        "SocialLinks",
        "SotProfiles",
        "Map",
        "Cards",
        "InfoBlock",
        "ProsecutorOffice",
        "Appeal",
        "InterCoopFile",
        "Statistic",
        "Accordion",
        "Reference",
        "QrDataAppeal",
        "CreatePage",
        "Constitution",
    ],
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
});
