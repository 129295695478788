import { ApexOptions } from "apexcharts";
import ru from "apexcharts/dist/locales/ru.json";
import { diagramColors } from "src/constants";
import { getTitleByLanguage } from "src/locales";

export const generatePieOptions = (data: DataType) => {
    const title = getTitleByLanguage(data);

    const nameValue = data?.diagramFields
        ?.filter((el) => el.fieldValue)
        ?.map((field) => ({
            name: getTitleByLanguage(field),
            data: field.fieldValue,
        }));
    const defaultTotal = nameValue?.map(el => el.data)?.reduce((acc, el) => acc + el)

    const options: ApexOptions = {
        colors: diagramColors,
        series: nameValue?.map((el) => el.data),
        labels: nameValue?.map((el) => el.name),
        chart: {
            type: "pie",
            redrawOnParentResize: true,
            locales: [ru],
            defaultLocale: "ru",
            toolbar: {
                autoSelected: "pan",
                show: true,
                export: {
                    csv: {
                        filename: title,
                    },
                    png: {
                        filename: title,
                    },
                    svg: {
                        filename: title,
                    },
                },
            },
        },
        tooltip: {
            custom: ({ seriesIndex, dataPointIndex, w }) => {
                const value = nameValue?.[seriesIndex]?.data
                const name = nameValue?.[seriesIndex]?.name
                const total = data.totalPercentage || defaultTotal
                const percentage = ((value / total) * 100).toFixed(2)
                return (
                    `<div style="padding: 0px; max-width: 300px;">
                        <div 
                        style="padding: 5px 16px; background: #0A164F; max-width: 300px" >
                            <h3 style="font-weight: 500;
                                font-size: 14px; color: white; 
                                line-height: 14px; display: inline-block; word-wrap: break-word; text-align: start; white-space: pre-line; ">
                            ${title}
                            </h3>
                        </div>
                        <div style="background: white;display: flex; align-items: center; flex-wrap: wrap;
                        justify-content: space-between; width: 100%; gap: 6px; padding: 3px 16px;">
                            <div style="display: flex; align-items: center; gap: 6px;">
                                <span style="color: green; font-size: 30px; line-height">•</span>
                                <p style="text-align: start; margin-top: -10px; white-space: pre-line; word-wrap: break-word; color: black;">
                                ${name}
                                </p>
                            </div>
                            <div style="display: flex; align-items: center; gap: 6px; ">
                                <p style="font-weight: 500; font-size: 15px; color: black; word-wrap: white-space: pre-line; break-word;">
                                ${value.toLocaleString()}
                                </p> 
                                <span style="color: #0A164F">|</span>
                                <p style="font-weight: 500; color: #0A164F; font-size: 15px;">${percentage}%</p>
                            </div>
                        </div>
                    <div>`
                )
            },
        },
        stroke: {
            width: 0,
            colors: diagramColors,
        },
        dataLabels: {
            style: {
                colors: ["black"],
                fontWeight: 300,
                fontSize: "18px",
            },
        },
        fill: {
            opacity: 0.8,
        },
        legend: {
            position: "right",
        },
        responsive: [
            {
                breakpoint: 567,
                options: {
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    };
    return { options };
};
