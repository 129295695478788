import ReactApexChart from "react-apexcharts";
import { generateRowOptions } from "../options/generate-row-options";

export const HorizontalChart = ({ data }) => {
    const { options } = generateRowOptions(data);
    return (
        <ReactApexChart
            options={options}
            series={options.series}
            type="bar"
            height={450}
        />
    );
};
