export const baseApi = (url: string) => `api/v1/${url}`;

export class Path {
    static Auth = class {
        static signIn = baseApi("auth/login");
        static signOut = baseApi("auth/logout");
        static refreshToken = baseApi("auth/refresh/token");
        static registrationAuth = baseApi("auth/registration");
        static OtpVerification = baseApi("auth/verify-otp");
    };
    static ProsecutorOffice = class {
        static create = baseApi("prosecutor-office");
        static getAll = baseApi("prosecutor-office");
        static search = baseApi("prosecutor-office/search");
        static getById = (id: number | string) =>
            baseApi(`prosecutor-office/${id}`);
        static update = (id: number | string) =>
            baseApi(`prosecutor-office/${id}`);
        static delete = (id: number | string) =>
            baseApi(`prosecutor-office/${id}`);
    };
    static Constitution = class {
        static get = (id: number | string) => baseApi(`constitution/${id}`);
        static search = baseApi("constitution/search");
        static getSection = (id: number | string) =>
            baseApi(`constitution/section/${id}`);
        static searchSection = baseApi("constitution/section/search");
        static getChapter = (id: number | string) =>
            baseApi(`constitution/chapter/${id}`);
        static searchChapter = baseApi("constitution/chapter/search");
        static create = baseApi("constitution");
        static update = baseApi("constitution");
    };
    static Reference = class {
        static get = (id: string) => baseApi(`common-references/${id}`);
        static getPublish = baseApi(`news/get-published`);
        static create = baseApi(`common-references`);
        static update = (id: number) => baseApi(`common-references/${id}`);
        static search = baseApi(`common-references/search`);
        static getAllTypeCode = baseApi(`common-references-types`);
        static getByTypeCode = (code: string) =>
            baseApi(`common-references/get-all-by-type-code/${code}`);
    };
    static CardInfo = class {
        static create = baseApi("card-info");
        static getAll = baseApi("card-info");
        static search = baseApi("card-info/search");
        static searchAll = baseApi("card-info/search/all");
        static getById = (id: number | string) => baseApi(`card-info/${id}`);
        static update = (id: number | string) => baseApi(`card-info/${id}`);
        static delete = (id: number | string) => baseApi(`card-info/${id}`);
        static postType = (id: number | string) =>
            baseApi(`card-info/type/${id}`);
    };
    static Photo = class {
        static create = baseApi("photos");
        static getById = (id: number | string) => baseApi(`photos/${id}`);
        static isMain = (id: number | string) => baseApi(`photos/${id}`);
        static delete = (id: number | string) => baseApi(`/${id}`);
        static getAllByAlbum = (photoAlbumId?: number) => baseApi(`photos/get-all?photoAlbumId=${photoAlbumId}`);
        static getAllOriginalByAlbum = (photoAlbumId?: number) => baseApi(`photos/get-all-original?photoAlbumId=${photoAlbumId}`);
    };
    static Search = class {
        static employeesGetToSign = baseApi("employee-positions/to-sign");
        static newsSearch = baseApi("news/search");
        static pageSearch = baseApi(`page-list/search/`);
        static organSearch = baseApi("organs/search");
        static connectTypeSearch = baseApi(
            "common-references/get-connect-type"
        );
        static getEntityTypeSearch = baseApi(
            "common-references/get-entity-type"
        );
    };
    static Profile = class {
        static passwordUpdate = baseApi(`employees/change-password`);
    };
    static Employee = class {
        static getMe = baseApi(`employees/me`);
        static editPhone = (phone: string) =>
            baseApi(`employees/change-phone?phone=${phone}`);
        static editEmail = (email: string) =>
            baseApi(`employees/change-email?email=${email}`);
    };
    static News = class {
        static get = (id: string) => baseApi(`news/${id}`);
        static delete = (id: number) => baseApi(`news/${id}/delete`);
        static getPublish = baseApi(`news/get-published`);
        static create = baseApi(`news`);
        static update = (id: number) => baseApi(`news/${id}`);
        static search = baseApi(`news/search`);
    };

    static Page = class {
        static search = baseApi(`page-list/search`);
        static getAll = baseApi(`page-list`);
        static create = baseApi(`page-list/create`);
        static update = (id: number) => baseApi(`page-list/${id}/update`);
        static get = (id?: string) => baseApi(`page-list/${id}`);
        static structure = baseApi(`sidebar/page-list-structure`);
        static delete = (id: number) => baseApi(`page-list/${id}`);
    };
    static ContentPage = class {
        static search = baseApi(`content-page/search`);
        static create = baseApi(`content-page`);
        static update = (id: number) => baseApi(`content-page/${id}/update`);
        static get = (id?: string) => baseApi(`content-page/${id}`);
        static delete = (id: number) => baseApi(`content-page/${id}`);
        static getALl = baseApi(`content-page/get-all`);
    };
    static Document = class {
        static divisionBlanks = baseApi("divisions/blanks");
    };
    static Setting = class {
        static get = baseApi(`settings`);
        static getByType = (type: string) => baseApi(`settings/${type}`);
        static update = (type: string) => baseApi(`settings/${type}`);
    };
    static Attachments = class {
        static get = (id: number, uuid: string) =>
            baseApi(`attachments/${id}/${uuid}`);
        static preview = (id: number | string, uuid: string | number) =>
            baseApi(`attachments/${id}/${uuid}/preview`);
    };
    static PageList = class {
        static getContent = (id: number | string) => baseApi(`page-list/${id}`);
        static updateContent = (id: number | string) =>
            baseApi(`page-list/${id}/update`);
        static create = baseApi(`page-list/create`);
        static search = baseApi(`page-list/search`);
    };
    static Navbar = class {
        static division = baseApi(`sidebar/divisions-structure`);
        static nomenclature = baseApi(`sidebar/nomenclature-structure`);
        static organ = baseApi(`sidebar/organ-structure`);
        static pageList = baseApi(`sidebar/page-list-structure`);
    };
    static PageListAccordion = class {
        static create = baseApi(`page-list-accordion`);
        static get = (id: number) => baseApi(`page-list-accordion/${id}`);
        static getAll = baseApi(`page-list-accordion/search/all`);
        static delete = (id: number) => baseApi(`page-list-accordion/${id}`);
        static update = (id: number) =>
            baseApi(`page-list-accordion/${id}/update`);
        static search = baseApi("page-list-accordion/search");
    };
    static Banners = class {
        static create = baseApi(`banner`);
        static getAll = baseApi(`banner`);
        static get = (id: number | string) => baseApi(`banner/${id}`);
        static disable = baseApi(`banner/disable`);
        static bannerSearch = baseApi(`banner/search`);
        static delete = (id: number | string) => baseApi(`banner/${id}`);
        static updateBanners = (id: number | string) => baseApi(`banner/${id}`);
    };
    static SotProfiles = class {
        static create = baseApi(`profile-data`);
        static getByType = baseApi(`profile-data/type`);
        static getAll = baseApi(`profile-data`);
        static sotProfilesrSearch = baseApi(`profile-data/search`);
        static get = (id: number | string) => baseApi(`profile-data/${id}`);
        static delete = (id: number | string) => baseApi(`profile-data/${id}`);
        static updateSotProfiles = (id: number | string) =>
            baseApi(`profile-data/${id}`);
    };
    static OnlineAppeal = class {
        static getAll = baseApi("online-appeal");
        static get = (id: number | string) => baseApi(`online-appeal/${id}`);
        static create = baseApi("online-appeal");
        static search = baseApi("online-appeal/search");
    };
    static Map = class {
        static create = baseApi("map-point");
        static getAll = baseApi("map-point");
        static search = baseApi("map-point/search");
        static getById = (id: number | string) => baseApi(`map-point/${id}`);
        static update = (id: number | string) => baseApi(`map-point/${id}`);
        static delete = (id: number | string) => baseApi(`map-point/${id}`);
    };
    static InfoBlock = class {
        static create = baseApi("info-block");
        static getAll = baseApi("info-block");
        static search = baseApi("info-block/search");
        static getById = (id: number | string) => baseApi(`info-block/${id}`);
        static update = (id: number | string) => baseApi(`info-block/${id}`);
        static delete = (id: number | string) => baseApi(`info-block/${id}`);
        static getAllType = (id: number | string) =>
            baseApi(`info-block/type/${id}`);
    };
    static Diagram = class {
        static create = baseApi("diagram");
        static getAll = baseApi("diagram");
        static search = baseApi("diagram/search");
        static searchAll = baseApi("diagram/search/all");
        static updateFull = baseApi(`diagram/update-full`);
        static getById = (id: number | string) => baseApi(`diagram/${id}`);
        static getAllYears = baseApi("diagram/year");
        static delete = (id: number | string) => baseApi(`diagram/${id}`);
    };
    static QrAppealInfo = class {
        static getData = (g: string, p: string) => baseApi(`qr?g=${g}&p=${p}`);
    };
    static DiagramSpecification = class {
        static getAllSpecific = baseApi("diagram-specification");
        static getSpecific = (id: number | string) =>
            baseApi(`diagram-specification/${id}`);
        static createSpecific = baseApi("diagram-specification");
        static search = baseApi("diagram-specification/search");
        static updateSpecific = (id: number | string) =>
            baseApi(`diagram-specification/${id}`);
        static deleteSpecific = (id: number | string) =>
            baseApi(`diagram-specification/${id}`);
        static searchSpecific = baseApi("diagram-specification/search");
    };
    static CommonReference = class {
        static getAllByTypeCode = (code: number | string) =>
            baseApi(`common-references/get-all-by-type-code/${code}`);
        static create = baseApi("common-references");
        static update = (id: number) => baseApi(`common-references/${id}`);
    };
    static InterCoopFile = class {
        static create = baseApi("inter-coop-file");
        static getAll = baseApi("inter-coop-file");
        static getById = (id: number | string) =>
            baseApi(`inter-coop-file/${id}`);
        static update = (id: number | string) =>
            baseApi(`inter-coop-file/${id}`);
        static search = baseApi("inter-coop-file/search");
        static delete = (id: number | string) =>
            baseApi(`inter-coop-file/${id}`);
    };
    static GoogleAnalytics = class {
        static getData = baseApi("google-analytics-data")
    }
}
