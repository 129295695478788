import { MantineProvider, createTheme } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { FC } from "react";
import { AppThemeProviderProps } from "src/types";

export const AppMantineProvider: FC<AppThemeProviderProps> = ({ children }) => {
    return (
        <MantineProvider theme={theme}>
            <Notifications />
            {children}
        </MantineProvider>
    );
};

const generateColors = (color: string) => {
    return Array(10).fill(color) as [
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
    ];
};

const theme = createTheme({
    fontFamily: "Rubik, sans-serif",
    breakpoints: {
        x2s: "450px",
        xs: "576px",
        sm: "768px",
        md: "992px",
        lg: "1200px",
        xl: "1440px",
        xxl: "1600px",
        xxxl: "1800px",
    },

    fontSizes: {
        "3xl": "30px",
        "2xl": "24px",
        xl: "20px",
        lg: "18px",
        md: "16px",
        sm: "14px",
    },
    headings: {
        sizes: {
            h1: {
                fontSize: "30px",
                fontWeight: "500",
                lineHeight: "30px",
            },
            h2: {
                fontSize: "24px",
                fontWeight: "500",
                lineHeight: "24px",
            },
            h3: {
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "20px",
            },
            h4: {
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "18px",
            },
            h5: {
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "16px",
            },
            h6: {
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "14px",
            },
        },
    },
    colors: {
        blueDark: generateColors("#101828"),
        primaryDark: generateColors("#0A164F"),
        primary: generateColors("#ffffff"),
        secondaryDark: generateColors("#0B1F7C"),
        secondary: generateColors("#101828"),
        gray: generateColors("#555F71"),
        weakGray: generateColors("rgba(11, 31, 124, 0.5)"),
        weakWhite: generateColors("rgba(255,255,255,0.04)"),
        weakPrimaryDark: generateColors("rgba(1, 14, 71, 0.5)"),
        lightBlue: generateColors("#1AB1E0"),
        softBlue: generateColors("#106F9E"),
        oceonBlue: generateColors("#187FDB"),
        weakBlue: generateColors("rgba(7, 21, 87, 0.5)"),
        weakDark: generateColors("#1A1C19"),
        strongGray: generateColors("#414941"),
    },
    components: {
        Button: {
            defaultProps: {
                variant: "primary",
            },
            styles: (
                theme: "dark" | "white",
                { variant }: { variant: string }
            ) => {
                if (variant === "primary") {
                    return {
                        root: {
                            boxShadow:
                                "0px 4px 24px 0px rgba(106, 122, 152, 0.15)",
                            padding: "10px 20px",
                            borderRadius: "2px",
                            border: "none",
                            cursor: "pointer",
                            display: "flex",
                            gap: "10px",
                        },
                    };
                }
                if (variant === "simple") {
                    return {
                        root: {
                            border: "none",
                            padding: "0 8px",
                            background: "none",
                            cursor: "pointer",
                        },
                    };
                }
                if (variant === "empthy") {
                    return {
                        root: {
                            border: "none",
                            background: "none",
                            padding: "0",
                            margin: "0",
                            cursor: "pointer",
                        },
                    };
                }
                if (variant === "circle") {
                    return {
                        root: {
                            border: "none",
                            width: "30px",
                            height: "30px",
                            margin: "0",
                            cursor: "pointer",
                            borderRadius: "50%",
                            padding: "3px 0 0 4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        },
                    };
                }
            },
        },
        Paper: {
            defaultProps: {
                variant: "bg-white",
            },
            styles: (
                theme: "dark" | "white",
                { variant }: { variant: string }
            ) => {
                if (variant === "bg-white") {
                    return {
                        root: {
                            background: "white",
                            WebkitBoxShadow:
                                " 0 0 31px -4px rgba(106, 122, 152, 0.2)",
                            MozBoxShadow:
                                "0 0 31px -4px rgba(106, 122, 152, 0.2)",
                            boxShadow:
                                " 0 0 31px -4px rgba(106, 122, 152, 0.2)",
                        },
                    };
                }
                if (variant === "bordered") {
                    return {
                        root: {
                            background: "white",
                            WebkitBoxShadow:
                                "1px -11px 31px -4px rgba(106, 122, 152, 0.15)",
                            MozBoxShadow:
                                "1px -11px 31px -4px rgba(106, 122, 152, 0.15)",
                            boxShadow:
                                "1px -11px 31px -4px rgba(106, 122, 152, 0.15)",
                            border: "1px solid #0A164F4D",
                            position: "relative",
                            borderRadius: "2px",
                        },
                    };
                }
                if (variant === "slide") {
                    return {
                        root: {
                            width: "100vw",
                            height: "250px",
                            position: "relative",
                            display: "inline-block",
                        },
                    };
                }
            },
        },
        Flex: {
            defaultProps: {
                variant: "default",
            },
            styles: (
                theme: "dark" | "white",
                { variant }: { variant: string }
            ) => {
                if (variant === "bg-white") {
                    return {
                        root: {
                            background: "white",
                            WebkitBoxShadow:
                                "1px -11px 31px -4px rgba(106, 122, 152, 0.15)",
                            MozBoxShadow:
                                "1px -11px 31px -4px rgba(106, 122, 152, 0.15)",
                            boxShadow:
                                "1px -11px 31px -4px rgba(106, 122, 152, 0.15)",
                        },
                    };
                }
            },
        },
        Text: {
            defaultProps: {
                variant: "default",
            },
            styles: (
                theme: "dark" | "white",
                { variant }: { variant: string }
            ) => {
                if (variant === "default") {
                    return {
                        root: {
                            color: "gray",
                        },
                    };
                }
            },
        },
        Title: {
            defaultProps: {
                variant: "default",
            },
            styles: (
                theme: "dark" | "white",
                { variant }: { variant: string }
            ) => {
                if (variant === "default") {
                    return {
                        root: {
                            color: "#1a1a21",
                            marginTop: "1rem",
                            paddingBottom: "1rem",
                        },
                    };
                }
            },
        },
    },
});
