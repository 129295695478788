import {
    Accordion,
    Box,
    Center,
    Flex,
    Grid,
    Image,
    Popover,
    ScrollArea,
    Tabs,
    Text,
    Title
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomLoader } from "src/components";
import { DIAGRAM_TYPES, getStatisticQuarterData } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import { HorizontalChart, PieChart, VerticalChart } from "./charts";
import styles from "./styles.module.scss";

interface StatisticCategoryStateType {
    parentId?: number;
    childrenId?: number;
}
type withId = { id: number };
interface StatisticModuleProps {
    data?: SearchDiagramResponseContentType[];
    activeCategory?: StatisticCategoryStateType;
    setActiveCategory: (a: StatisticCategoryStateType | undefined) => void;
    isLoading: boolean;
    categories: DiagramSpecificResponseType[];
    withDefaultChevron?: boolean;
}

export const StatisticBody = ({
    data,
    categories,
    activeCategory,
    setActiveCategory,
    isLoading,
    withDefaultChevron = true,
}: StatisticModuleProps) => {
    const [active, setActive] = useState<string | null>();
    const { t } = useTranslation();
    const quarterData = getStatisticQuarterData(t);
    const middleScreen = useMediaQuery(`(max-width: 992px)`);
    const [opened, { toggle, close }] = useDisclosure(false);

    const categoryChangeHandler = (item: withId, child?: withId) => () => {
        const parentId = item.id;
        const childrenId = child?.id;
        setActiveCategory(
            parentId === activeCategory?.parentId &&
                childrenId === activeCategory?.childrenId
                ? undefined
                : { parentId, childrenId }
        );
        if (child?.id) {
            close();
        }
    };

    const getComponent = (data?: SearchDiagramResponseContentType) => {
        switch (data?.type) {
            case DIAGRAM_TYPES.CLASSIC:
                return <VerticalChart data={data} />
            case DIAGRAM_TYPES.RADIUS_PIE:
                return <PieChart data={data} />
            case DIAGRAM_TYPES.VERTICAL_BAR:
                return <VerticalChart data={data} />
            case DIAGRAM_TYPES.HORIZONTAL_BAR:
                return <HorizontalChart data={data} />
            case DIAGRAM_TYPES.PIE:
                return <PieChart data={data} />
            default:
                return <HorizontalChart data={data} />
        }
    }
    const categoryModule = () => {
        if (middleScreen) {
            return (
                <Popover opened={opened} onChange={toggle} position="bottom">
                    <Popover.Target>
                        <Center onClick={toggle} style={{ cursor: "pointer" }}>
                            <Text c="primaryDark" fw={400} fz={20}>
                                {t("titles.statistic-categories")}
                            </Text>
                        </Center>
                    </Popover.Target>
                    <Popover.Dropdown w="90vw" style={{ borderRadius: "none" }}>
                        <Accordion
                            chevron={withDefaultChevron ? undefined : false}
                        >
                            {categories?.map((item) => (
                                <Accordion.Item
                                    data-background={
                                        activeCategory?.parentId === item.id
                                    }
                                    className={styles.accordion_item}
                                    key={item.id}
                                    value={`${item.id}`}
                                >
                                    <Accordion.Control
                                        onClick={categoryChangeHandler(item)}
                                    >
                                        <Flex
                                            align="center"
                                            justify="space-between"
                                        >
                                            <Text
                                                c="primaryDark"
                                                fw={400}
                                                fz={20}
                                            >
                                                {getTitleByLanguage(item)}
                                            </Text>
                                            {!withDefaultChevron &&
                                                item.diagramSpecificationDtoList
                                                    ?.length && (
                                                    <Box>
                                                        <IconChevronDown
                                                            size={16}
                                                        />
                                                    </Box>
                                                )}
                                        </Flex>
                                    </Accordion.Control>
                                    <Accordion.Panel>
                                        <Flex direction="column" gap={15}>
                                            {item.diagramSpecificationDtoList?.map(
                                                (child) => (
                                                    <Text
                                                        style={{
                                                            cursor: "pointer",
                                                            opacity:
                                                                activeCategory?.childrenId ===
                                                                    child?.id
                                                                    ? 0.5
                                                                    : 1,
                                                        }}
                                                        onClick={categoryChangeHandler(
                                                            item,
                                                            child
                                                        )}
                                                        key={child.id}
                                                        c={
                                                            activeCategory ===
                                                                child.id
                                                                ? "green"
                                                                : "primaryDark"
                                                        }
                                                        fw={400}
                                                        fz={18}
                                                    >
                                                        {getTitleByLanguage(
                                                            child
                                                        )}
                                                    </Text>
                                                )
                                            )}
                                        </Flex>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </Popover.Dropdown>
                </Popover>
            );
        }
        return (
            <ScrollArea scrollbarSize={6} h={600}>
                <Accordion
                    chevron={withDefaultChevron ? undefined : false}
                    value={active}
                    onChange={setActive}
                >
                    {categories?.map((item) => (
                        <Accordion.Item
                            data-background={
                                activeCategory?.parentId === item.id
                            }
                            className={styles.accordion_item}
                            key={item.id}
                            value={`${item.id}`}
                        >
                            <Accordion.Control
                                onClick={categoryChangeHandler(item)}
                            >
                                <Flex align="center" justify="space-between">
                                    <Text c="primaryDark" fw={400} fz={20}>
                                        {getTitleByLanguage(item)}
                                    </Text>
                                    {!withDefaultChevron &&
                                        item.diagramSpecificationDtoList
                                            ?.length && (
                                            <Box>
                                                <IconChevronDown size={16} />
                                            </Box>
                                        )}
                                </Flex>
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Flex direction="column" gap={15}>
                                    {item.diagramSpecificationDtoList?.map(
                                        (child) => (
                                            <Text
                                                style={{
                                                    cursor: "pointer",
                                                    opacity:
                                                        activeCategory?.childrenId ===
                                                            child.id
                                                            ? 0.5
                                                            : 1,
                                                }}
                                                onClick={categoryChangeHandler(
                                                    item,
                                                    child
                                                )}
                                                key={child.id}
                                                c="primaryDark"
                                                fw={400}
                                                fz={18}
                                            >
                                                {getTitleByLanguage(child)}
                                            </Text>
                                        )
                                    )}
                                </Flex>
                            </Accordion.Panel>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </ScrollArea>
        );
    };

    return (
        <Grid pos="relative" justify="space-between">
            <Grid.Col
                mr={10}
                mb={{ base: 8, md: 0 }}
                style={{ border: "1px solid #0A164F", borderRadius: "2px" }}
                span={{ base: 12, md: 3.8 }}
            >
                {categoryModule()}
            </Grid.Col>
            <Grid.Col
                style={{ border: "1px solid #0A164F", borderRadius: "2px" }}
                span={{ base: 12, md: 8 }}
            >
                <ScrollArea pos="relative" h={600}>
                    {isLoading ?
                        <Box pos="relative" w="100%" mih="40vh" h="100%">
                            <CustomLoader />
                        </Box> : quarterData.map((items) => (
                            <Tabs.Panel
                                h="100%"
                                value={items.value}
                                key={items.value}
                                mr={20}
                            >
                                {data?.length ? (
                                    data?.map((datas) => {
                                        return (
                                            <Box
                                                ta="center"
                                                py={{ base: 12, md: 30 }}
                                                my={10}
                                                key={datas.id}
                                            >
                                                <Title
                                                    fz={{
                                                        base: 14,
                                                        md: 18,
                                                        lg: 24,
                                                    }}
                                                    c="primaryDark"
                                                    lh={1.2}
                                                    mt={0}
                                                >
                                                    {getTitleByLanguage(datas)}
                                                </Title>
                                                {getComponent(datas)}
                                            </Box>
                                        );
                                    })
                                ) : (
                                    <Flex
                                        align="center"
                                        w="100%"
                                        h={550}
                                        justify="center"
                                    >
                                        <Image
                                            w={{ base: 120, md: 200 }}
                                            src={
                                                "/assets/statisticPlaceholder.webp"
                                            }
                                        />
                                    </Flex>
                                )}
                            </Tabs.Panel>
                        ))}
                </ScrollArea>
            </Grid.Col>
        </Grid>
    );
};
