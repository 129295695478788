import {
    Box,
    Button,
    Container,
    Divider,
    Flex,
    Grid,
    Group,
    NumberInput,
    Paper,
    Select,
    Text,
    Title
} from "@mantine/core";
import { UseFormReturnType, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconCheck, IconTrash } from "@tabler/icons-react";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    CustomButton,
    CustomInputText,
    CustomSelect,
    SelectDropdown
} from "src/components";
import { DIAGRAM_REFERENCE_TYPES } from "src/constants";
import { diagramChartTypes, diagramInitialValues, quartalTypeArray } from "src/constants/diagram";
import { getTitleByLanguage } from "src/locales";
import {
    useDiagramCreateMutation,
    useDiagramSpecificGetAllQuery,
    useDiagramUpdateMutation,
    useGetAllByTypeCodeQuery,
} from "src/store";
import { TypeCodesEnum } from "src/types";
import { notify } from "src/utils";

type Props = {
    diagram?: DiagramResponseType;
};


export const DiagramForm = ({ diagram }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [activeCategory, setActiveCategory] = useState<number | null>(null);
    const [createDiagram] = useDiagramCreateMutation();
    const [updateDiagram] = useDiagramUpdateMutation();
    const { data: categoriesData } = useDiagramSpecificGetAllQuery([]);
    const [openned, { toggle }] = useDisclosure()
    const filteredCategoriesData = useMemo(() => {
        return (
            categoriesData
                ?.filter((el) => el.diagramSpecificationDtoList?.length)
                ?.reverse() || []
        );
    }, [categoriesData]);

    const { data: reference } = useGetAllByTypeCodeQuery(TypeCodesEnum.NEWS_CATEGORY)
    const referenceID = reference?.map(el => ({
        label: getTitleByLanguage(el),
        value: `${el.id}`
    }))


    const form = useForm<DiagramRequestType>({
        initialValues: !diagram
            ? diagramInitialValues
            : {
                id: diagram?.id,
                titleKg: diagram?.titleKg || "",
                titleRu: diagram?.titleRu || "",
                titleEn: diagram?.titleEn === "true" ? "true" : "false",
                description: "",
                diagramQuarter: diagram.diagramQuarter || "",
                year: diagram.year || 2023,
                diagramSpecificationId: diagram?.diagramSpecificationDto?.id || null,
                type: diagram?.type || "",
                order: diagram?.order || 200,
                textEn: diagram?.textEn || "",
                textRu: diagram?.textRu || "",
                textKg: diagram?.textKg || "",
                totalPercentage: diagram?.totalPercentage || 1000,
                toDeleteDiagramField: [],
                toDeleteDiagramFieldValue: [],
                diagramTypeId: diagram?.diagramType?.id,
                fieldRequest: [
                    ...diagram?.diagramFields?.map((field, index) => {
                        return {
                            id: field.id,
                            code: field?.code || "",
                            order: field?.order || index + 1,
                            titleRu: field?.titleRu,
                            titleKg: field?.titleKg,
                            fieldValue: field?.fieldValue || 0,
                            valuesRequest: [],
                        };
                    }),
                ],
            },
        validate: {
            titleRu: (value) => !value && t("form.validation.required"),
            titleKg: (value) => !value && t("form.validation.required"),
        },
    });

    useEffect(() => {
        if (activeCategory) {
            form.setFieldValue("diagramSpecificationId", activeCategory);
        }
    }, [activeCategory]);

    useEffect(() => {
        setActiveCategory(form.values.diagramSpecificationId);
    }, [form.values]);

    const handleDeleteField = (idx: number) => {
        const requestLength = form.values.fieldRequest.length

        const fieldId = form.values.fieldRequest.find((el, index) => index === idx)?.id
        form.removeListItem("fieldRequest", idx);

        if (fieldId) {
            if (idx === 0 && !(requestLength > 1)) {
                form.insertListItem("fieldRequest", {
                    titleRu: "",
                    titleKg: "",
                    fieldValue: "",
                    valuesRequest: []
                })
            }
            form.insertListItem("toDeleteDiagramField", fieldId)
        }
    }


    const handleSubmit = async (values: DiagramRequestType, isSaveLikeNew: boolean) => {
        if (isSaveLikeNew) {
            handleCreate(values)
        } else {
            handleUpdate(values)
        }
    };

    const handleUpdate = async (values: DiagramRequestType) => {
        try {
            await updateDiagram({ body: values }).unwrap();
            notify(true, t("notify.updated"));
            navigate("/diagram");
        } catch {
            notify(false);
        }
    }

    const handleCreate = async (values: DiagramRequestType) => {
        try {
            await createDiagram(values).unwrap();
            notify(true, t("notify.saved"));
            form.reset();
            navigate("/diagram");
        } catch {
            notify(false);
        }
    }

    const mainPage = useMemo(() => {
        return reference?.find(el => el.id == form.values.diagramTypeId)?.titleEn === DIAGRAM_REFERENCE_TYPES.MAIN_PAGE
    }, [form])
    const statisticPage = useMemo(() => {
        return reference?.find(el => el.id == form.values.diagramTypeId)?.titleEn === DIAGRAM_REFERENCE_TYPES.STATISTIC
    }, [form])
    const organPage = useMemo(() => {
        return reference?.find(el => el.id == form.values.diagramTypeId)?.titleEn === DIAGRAM_REFERENCE_TYPES.ORGAN
    }, [form])


    const diagramChartsID = useMemo(() => {
        return diagramChartTypes(t, organPage)
    }, [form, t])

    const fields = form.values.fieldRequest?.sort((a, b) => a.order - b.order).map((_item, index) => (
        <FieldItem
            mainPage={mainPage}
            key={index}
            form={form}
            length={form.values.fieldRequest.length}
            handleDeleteField={handleDeleteField}
            index={index}
        />
    ));

    return (
        <Container size="xl" p={0}>
            <form onSubmit={form.onSubmit(values => handleCreate(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        <Grid.Col span={12}>
                            <Select
                                mt={15}
                                {...form.getInputProps("diagramTypeId")}
                                value={`${form.values.diagramTypeId}`}
                                placeholder={t`form.label.type`}
                                required
                                error={form.errors.type}
                                data={referenceID}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <NumberInput
                                label={t`form.label.importance`}
                                required
                                error={form.errors.order}
                                {...form.getInputProps("order")}
                            />
                        </Grid.Col>
                        {statisticPage ?
                            <Grid.Col span={12}>
                                {filteredCategoriesData ? (
                                    <SelectDropdown
                                        isAdminPage
                                        value={activeCategory || undefined}
                                        onChange={(value) =>
                                            setActiveCategory(value)
                                        }
                                        data={filteredCategoriesData}
                                    />
                                ) : null}
                            </Grid.Col> : null}
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <CustomInputText
                                {...form.getInputProps("titleRu")}
                                label={t`form.label.titleRu`}
                                required
                                error={form.errors.titleRu}
                                formData={form.values.titleRu}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <CustomInputText
                                {...form.getInputProps("titleKg")}
                                label={t`form.label.titleKg`}
                                required
                                error={form.errors.titleKg}
                                formData={form.values.titleKg}
                            />
                        </Grid.Col>
                        {mainPage ?
                            <>
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <CustomInputText
                                        {...form.getInputProps("textRu")}
                                        label={t`form.label.textRu`}
                                        error={form.errors.textRu}
                                        formData={form.values.textRu}
                                    />
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <CustomInputText
                                        {...form.getInputProps("textKg")}
                                        label={t`form.label.textKg`}
                                        error={form.errors.textKg}
                                        formData={form.values.textKg}
                                    />
                                </Grid.Col>
                            </> : null}
                        {!mainPage ?
                            <>
                                <Grid.Col span={{ base: 12, md: 4 }}>
                                    <Select
                                        mt={15}
                                        {...form.getInputProps("type")}
                                        value={`${form.values.type}`}
                                        placeholder={t`form.label.diagramType`}
                                        required
                                        error={form.errors.type}
                                        data={diagramChartsID}
                                    />
                                </Grid.Col>
                                <Grid.Col
                                    span={{ base: 12, md: 4 }}
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        width: "100%",
                                    }}
                                >
                                    <NumberInput w="100%" mt={-15}
                                        label={t("form.label.year")}
                                        required
                                        {...form.getInputProps("year")}
                                        placeholder={t("form.label.year")}
                                        max={2030}
                                        min={2015}
                                    />
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 4 }}>
                                    <CustomSelect
                                        required
                                        placeholder={t`form.label.diagramQuarter`}
                                        {...form.getInputProps("diagramQuarter")}
                                        formData={form.values.diagramQuarter}
                                        error={form.errors.diagramQuarter}
                                        data={quartalTypeArray(t)}
                                    />
                                </Grid.Col>
                            </>
                            : null}

                        <Grid.Col span={12}>
                            <Title c="primaryDark" p={0}>{t("form.label.value")}</Title>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Flex>
                                <NumberInput
                                    inputMode="email"
                                    required
                                    {...form.getInputProps("totalPercentage")}
                                    label={t("form.label.totalPercentage")}
                                    min={1}
                                />
                            </Flex>
                            <Divider w="100%" mt={20} />
                            {fields}
                            <Button
                                mt={20}
                                onClick={() =>
                                    form.insertListItem("fieldRequest", {
                                        titleRu: "",
                                        titleKg: "",
                                        order: form.values.fieldRequest.length + 1,
                                        fieldValue: 0,
                                    })
                                }
                            >
                                {t("button.add-more")}
                            </Button>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Group style={{ display: "flex", justifyContent: "center" }} mt={3}>
                                <CustomButton
                                    label={t`button.cancel`}
                                    onClick={() => navigate(`/diagram/${i18n.language}`)}
                                />
                                {diagram ?
                                    <Box pos="relative">
                                        <CustomButton onClick={toggle}
                                            label={t`button.submit`}
                                        />
                                        <Paper
                                            display={openned ? "block" : "none"}
                                            top="-250%" right={-16}
                                            w={200} pos="absolute" style={{ cursor: "pointer" }}>
                                            <Box
                                                onClick={() => {
                                                    handleSubmit(form.values, true)
                                                    toggle()
                                                }}
                                                className="bg_onhover" py={8} px={16}>
                                                {t("button.save-like-new")}
                                            </Box>
                                            <Box
                                                onClick={() => {
                                                    handleSubmit(form.values, false)
                                                    toggle()
                                                }}
                                                className="bg_onhover" py={8} px={16}>
                                                {t("button.save-update")}
                                            </Box>
                                        </Paper>
                                    </Box> :
                                    <CustomButton
                                        type="submit"
                                        label={t`button.submit`}
                                    />}
                            </Group>
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
        </Container >
    );
};






type ItemProps = {
    index: number;
    handleDeleteField: (index: number) => void;
    form: UseFormReturnType<
        DiagramRequestType,
        (values: DiagramRequestType) => DiagramRequestType
    >;
    length: number;
    mainPage: boolean
};

const FieldItem = ({
    handleDeleteField,
    index,
    form,
    mainPage
}: ItemProps) => {
    const [newOrder, setNewOrder] = useState(form.values.fieldRequest?.[index]?.order);
    const { t } = useTranslation();

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setNewOrder(value)
    };
    const handleSaveOrder = (value: number) => () => {
        form.setFieldValue(`fieldRequest.${index}.order`, value)
    }

    const { data: typeCodes } = useGetAllByTypeCodeQuery(TypeCodesEnum.CARD_INFO_TYPE)
    const countriesID = typeCodes?.map(el => ({
        label: getTitleByLanguage(el),
        value: el.titleEn
    }))


    const percentageValue = useMemo(() => {
        const total = form.values.totalPercentage
        const currentValue = form.values.fieldRequest[index].fieldValue
        if (currentValue) {
            return (currentValue / total) * 100
        } else {
            return 0
        }
    }, [form])

    const handleChangeValue = (value: string | number) => {
        if (value && parseFloat(`${value}`)) {
            form.setFieldValue(`fieldRequest.${index}.fieldValue`, value)
        } else {
            form.setFieldValue(`fieldRequest.${index}.fieldValue`, 0)
        }
    }

    const handleOnChangePercentage = (value: string | number) => {
        const total = form.values.totalPercentage;
        const valueNum = parseFloat(`${value}`);
        if (value && !isNaN(valueNum)) {
            const valueFromPercentage = (valueNum / 100) * total;
            handleChangeValue(valueFromPercentage);
        } else {
            handleChangeValue(0);
        }
    };

    useEffect(() => {
        setNewOrder(form.values.fieldRequest?.[index]?.order);
    }, [index, form]);
    return (
        <>
            <Grid key={index} align="end" py={20}>
                <Grid.Col mt={15}>
                    <Grid>
                        {mainPage ?
                            <Grid.Col span={{ base: 12, md: 4 }}>
                                <Select
                                    mt={15}
                                    {...form.getInputProps(`fieldRequest.${index}.code`)}
                                    placeholder={t`form.label.country`}
                                    value={form.values.fieldRequest?.[index]?.code}
                                    required
                                    error={form.errors.type}
                                    data={countriesID}
                                />
                            </Grid.Col> : null}
                        <Grid.Col span={4}>
                            <Flex>
                                <input
                                    max={100}
                                    min={0}
                                    style={{
                                        width: "80px",
                                        border: "1px solid black",
                                        padding: "0 10px",
                                        background: "none",
                                        color: "black",
                                    }}
                                    onChange={handleOnChange}
                                    value={newOrder}
                                />
                                <Button
                                    onClick={handleSaveOrder(newOrder)}
                                    bg={newOrder === form.values.fieldRequest?.[index]?.order ? "gray" : "green"} px={5}>
                                    <IconCheck />
                                </Button>
                            </Flex>
                        </Grid.Col>

                    </Grid>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4 }}>
                    <Text lh={1.2} c="green">
                        {t("descriptions.recommended-40-words")}
                    </Text>
                    <CustomInputText
                        {...form.getInputProps(`fieldRequest.${index}.titleRu`)}
                        label={t`form.label.titleRu`}
                        formData={form.values.fieldRequest[index].titleRu}
                    />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4 }}>
                    <CustomInputText
                        {...form.getInputProps(`fieldRequest.${index}.titleKg`)}
                        label={t`form.label.titleKg`}
                        formData={form.values.fieldRequest[index].titleKg}
                    />
                </Grid.Col>
                <Grid.Col span={{ base: 6, md: 1.5 }}>
                    <NumberInput
                        label={t`form.label.value`}
                        value={form.values.fieldRequest[index].fieldValue}
                        onChange={handleChangeValue}
                        min={0}
                    />
                </Grid.Col>
                <Grid.Col span={{ base: 6, md: 1.5 }}>
                    <NumberInput
                        onChange={handleOnChangePercentage}
                        label={t`form.label.percentFrom` + " %"}
                        value={percentageValue}
                        min={0}
                    />
                </Grid.Col>
                <Grid.Col span={1}>
                    <Button
                        onClick={() => handleDeleteField(index)}
                        variant="simple"
                    >
                        <IconTrash color="red" />
                    </Button>
                </Grid.Col>
            </Grid>
            <Divider />
        </>
    );
};
