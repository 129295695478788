import { Carousel } from "@mantine/carousel";
import { Box, Button, Center, Flex, Paper, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CustomLoader } from "src/components/forms";
import { DIAGRAM_REFERENCE_TYPES } from "src/constants";
import { useDiagramSearchMutation, useGetAllByTypeCodeQuery } from "src/store";
import { TypeCodesEnum } from "src/types";
import { StatisticCarouselItem } from "./modules/statistic-carousel-item";

export const StatisticSliderSection = () => {
    const { t, i18n } = useTranslation();
    const [searchDiagramByDateQuartal, { data: statisticData, isLoading }] =
        useDiagramSearchMutation();
    const { data: typeCodes } = useGetAllByTypeCodeQuery(TypeCodesEnum.NEWS_CATEGORY)
    const typeId = useMemo(() => {
        return typeCodes?.find(el => el.titleEn === DIAGRAM_REFERENCE_TYPES.ORGAN)?.id
    }, [typeCodes])
    const handleSearchDiagram = async () => {
        if (typeId) {
            try {
                await searchDiagramByDateQuartal({
                    filter: {
                        diagramTypeId: typeId,
                        diagramQuarter: "FOURTH",
                        year: 2023
                    },
                    pageRequest: { limit: 15, page: 0 },
                    sorting: {
                        sortBy: "ORDER",
                        sortDirection: "ASC",
                    },
                });
            } catch {
                console.error("error");
            }
        }
    };
    useEffect(() => {
        handleSearchDiagram();
    }, [typeId]);
    return (
        <Paper h="fit-content" mb={30} py={15}>
            {statisticData?.content?.length ? (
                <Carousel
                    loop
                    data-statistic={true}
                    slideGap={0}
                    slideSize="100%"
                    w="100%"
                    slidesToScroll={1}
                    withIndicators
                    withControls={false}
                >
                    {isLoading ?
                        <Box pos="relative" w='100%' h={400}>
                            <CustomLoader />
                        </Box>
                        : statisticData?.content?.map((el, index) => (
                            <StatisticCarouselItem key={el.id} data={el} />
                        ))}
                </Carousel>
            ) : (
                <Center>
                    <Text fz={{ base: 16, md: 18, lg: 24 }}>
                        {t("error.not-found")}
                    </Text>
                </Center>
            )}
            <Flex px={16} w="100%" justify="end" align="end">
                <Button
                    component={Link}
                    to={`/statistics/${i18n.language}`}
                    bg="primaryDark"
                    c="primary"
                    fz="sm"
                    fw="500"
                >
                    {t("statics.more")}
                    <IconChevronRight size={20} />
                </Button>
            </Flex>
        </Paper>
    );
};
