import { Accordion, AccordionItem, Card, Flex, Image, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { getTitleByLanguage } from "src/locales";
import { HTMLRender } from "src/utils";

interface PersonnelCardProps {
    person: CardType;
}

export const PersonnelCardAccordion = ({ person }: PersonnelCardProps) => {
    const { t } = useTranslation();
    return (
        <Card style={{ borderRadius: "2px" }} p={24} w="100%">
            <Flex
                direction={{ base: "column", sm: "row" }}
                align="start"
                gap={30}
                w="100%"
            >
                {person.photo ? (
                    <Flex
                        h={{ base: 320, md: 280 }}
                        w={300}
                        style={{ overflow: "hidden" }}
                    >
                        <img
                            height="100%"
                            width="100%"
                            src={`data:image/webp;base64,${person?.photo}`}
                            style={{
                                objectFit: "contain",
                                objectPosition: "50% 50%",
                            }}
                            alt=""
                        />
                    </Flex>
                ) : (
                    <Flex
                        align="center"
                        justify="center"
                        bg="primaryDark"
                        w={260}
                        h={250}
                    >
                        <Image src="/icons/logo.svg" />
                    </Flex>
                )}
                <Flex w="100%" direction="column">
                    <Flex direction="column">
                        <Title
                            mb={-10}
                            fz={{ base: "md", md: "xl" }}
                            lh={1}
                            c="secondary"
                        >
                            {getTitleByLanguage(person?.position)}
                        </Title>
                        <Title
                            lh={1}
                            fz={{ base: "lg", md: "28px" }}
                            c="secondary"
                        >
                            {getTitleByLanguage(person?.fullName)}
                        </Title>
                    </Flex>
                    <Accordion w="100%">
                        <AccordionItem value="1">
                            <Accordion.Control
                                style={{
                                    borderBottom: "1px solid var(--borders)",
                                }}
                            >
                                {t("statics.biography")}
                            </Accordion.Control>
                            <Accordion.Panel>
                                <HTMLRender
                                    htmlContent={getTitleByLanguage(
                                        person?.shortBiography
                                    )}
                                />
                            </Accordion.Panel>
                        </AccordionItem>
                        <AccordionItem value="2">
                            <Accordion.Control
                                style={{
                                    borderBottom: "1px solid var(--borders)",
                                }}
                            >
                                {t("statics.education")}
                            </Accordion.Control>
                            <Accordion.Panel>
                                <HTMLRender
                                    htmlContent={getTitleByLanguage(
                                        person?.education
                                    )}
                                />
                            </Accordion.Panel>
                        </AccordionItem>
                        <AccordionItem value="3">
                            <Accordion.Control
                                style={{
                                    borderBottom: "1px solid var(--borders)",
                                }}
                            >
                                {t("statics.working-position")}
                            </Accordion.Control>
                            <Accordion.Panel>
                                <HTMLRender
                                    htmlContent={getTitleByLanguage(
                                        person?.awards
                                    )}
                                />
                            </Accordion.Panel>
                        </AccordionItem>
                        <AccordionItem value="4">
                            <Accordion.Control
                                style={{
                                    borderBottom: "1px solid var(--borders)",
                                }}
                            >
                                {t("links.reception-time")}
                            </Accordion.Control>
                            <Accordion.Panel>
                                <HTMLRender
                                    htmlContent={getTitleByLanguage(
                                        person?.laborActivity
                                    )}
                                />
                            </Accordion.Panel>
                        </AccordionItem>
                    </Accordion>
                </Flex>
            </Flex>
        </Card>
    );
};
