import { Accordion, Box, Flex } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import {
    IconAdjustments,
    IconBuildingBank,
    IconChartHistogram,
    IconChevronDown,
    IconFileBroken,
    IconGavel,
    IconLayoutDashboard,
    IconLink,
    IconMan,
    IconMapPin,
    IconMessageCheck,
    IconNews,
    IconPhotoScan,
    IconTablePlus,
} from "@tabler/icons-react";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppAction, useAppSelector } from "src/hooks";
import { sidebarPath } from "src/store/reducers/sidebar-slice";
import { RootState } from "src/store/store";
import { NavbarLink } from "./navbar-link";

type Props = {
    item: string;
    open?: boolean;
    children?: string[];
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const getIcon = (a: string) => {
    let IconComponent;

    switch (a) {
        case "NEWS":
            IconComponent = IconNews;
            break;
        case "PAGE":
            IconComponent = IconTablePlus;
            break;
        case "SOCIAL_LINK":
            IconComponent = IconLink;
            break;
        case "BANNER":
            IconComponent = IconPhotoScan;
            break;
        case "CARD_INFO":
            IconComponent = IconMan;
            break;
        case "REFERENCE":
            IconComponent = IconAdjustments;
            break;
        case "INFO_BLOCK":
            IconComponent = IconLayoutDashboard;
            break;
        case "PROSECUTOR_OFFICE":
            IconComponent = IconBuildingBank;
            break;
        case "DIAGRAM":
            IconComponent = IconChartHistogram;
            break;
        case "MAP_POINT":
            IconComponent = IconMapPin;
            break;
        case "INTER_COOP_FILE":
            IconComponent = IconFileBroken;
            break;
        case "ONLINE_APPEAL":
            IconComponent = IconMessageCheck;
            break;
        case "CONSTITUTION":
            IconComponent = IconGavel;
            break;
        default:
            IconComponent = IconMessageCheck;
    }
    return IconComponent;
};
export const LinksGroup = ({ item, open, setOpen, children }: Props) => {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation()
    const path = pathname.split("/")[1]
    const [value, setValue] = useState(path === "page" || path === "page_list_accordion" || path === "inter_coop_file" ? "1" : "2")
    const { classes, cx } = useStyles();
    const navigate = useNavigate();
    const profile = useAppSelector((state: RootState) => state.auth.profile);
    const lang = i18n.language;
    const { setActive } = useAppAction({ setActive: sidebarPath.addActive });
    const active = useAppSelector((state) => state.sidebarStore.active);

    const Icon = getIcon(item);

    const handleOnClick = (path: string) => () => {
        setActive(item);
        navigate(`/${[path]}/${lang}`);
    };

    return (
        <>
            {open ? (
                <Box style={{ cursor: "pointer" }}
                    h="auto"
                    py={8}
                    className={cx(classes.control, {
                        [classes.active]: active === item,
                    })}
                >
                    {children?.length ? (
                        <Accordion value={value} chevron={<IconChevronDown color="white" />} c="white" my={-5} px={10} py={0}>
                            <Accordion.Item onClick={() => setValue(prev => prev === "1" ? "2" : "1")} py={0} value="1">
                                <Accordion.Control py={0}>
                                    <Flex my={-10} align="center" c="white" >
                                        <Box >
                                            <Icon size={22} />
                                        </Box>
                                        <Box fw={500} c="white" ml="md" fz={14}>
                                            {t(
                                                `sidebar.${item?.toLocaleLowerCase()}`
                                            )}
                                        </Box>
                                    </Flex>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <Flex direction="column" gap={10}>
                                        <Box fw={400}
                                            style={{ cursor: "pointer" }}
                                            onClick={handleOnClick(
                                                item?.toLocaleLowerCase()
                                            )}
                                            c="white"
                                            ml="md"
                                        >
                                            {t(
                                                `sidebar.${item?.toLocaleLowerCase()}`
                                            )}
                                        </Box>
                                        {children?.map((el) => (
                                            <Box key={el} fw={400}
                                                style={{ cursor: "pointer" }}
                                                onClick={handleOnClick(
                                                    el?.toLocaleLowerCase()
                                                )}
                                                c="white"
                                                ml="md"
                                            >
                                                {t(
                                                    `sidebar.${el?.toLocaleLowerCase()}`
                                                )}
                                            </Box>
                                        ))}
                                    </Flex>
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    ) : (
                        <Flex px={10}
                            onClick={handleOnClick(item?.toLowerCase())}
                            align="center"
                        >
                            <Box>
                                <Icon color="white" size={22} />
                            </Box>
                            <Box c="white" ml="md">
                                {t(`sidebar.${item?.toLocaleLowerCase()}`)}
                            </Box>
                        </Flex>
                    )}
                </Box>
            ) : (
                <NavbarLink
                    label={
                        item === "USER" && profile
                            ? profile?.initials
                            : t(`sidebar.${item?.toLowerCase()}`)
                    }
                    icon={Icon}
                    onClick={handleOnClick(item?.toLowerCase())}
                />
            )}
        </>
    );
};

const useStyles = createStyles((theme) => ({
    control: {
        display: "block",
        minWidth: 233,
        width: "100%",
        height: 50,
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
        borderRadius: 0,
        fontSize: 14,
        fontWeight: 600,
        color: theme.white,
        "&:hover": {
            color: theme.white,
            borderRadius: 5,
            backgroundColor: "rgba(255,255,255, 0.05)",
        },
    },
    link: {
        fontWeight: 600,
        fontSize: 14,
        padding: `${theme.spacing.xs}px ${theme.spacing.xl}px`,
        paddingLeft: 18,
        cursor: "pointer",
        color: theme.white,
        "&:hover": {
            borderRadius: 5,
            backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
    },
    docLink: {
        padding: `${theme.spacing.xs}px ${theme.spacing.xs}px ${theme.spacing.xs}px ${theme.spacing.xl}px`,
        justifyContent: "space-between",
    },
    active: {
        borderRadius: 5,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
    chevron: {
        transition: "transform 0.3s ease",
    },
}));
