import { Grid, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import variablePie from "highcharts/modules/variable-pie.js";
import { useTranslation } from "react-i18next";
import { diagramColors } from "src/constants";
import { getTitleByLanguage } from "src/locales";
variablePie(Highcharts);

type Props = {
    data: SearchDiagramResponseContentType
}
export const RadiusPieItem = ({ data }: Props) => {
    const { i18n } = useTranslation()
    const values = data?.diagramFields?.map(el => ({
        z: el.fieldValue * 100,
        name: getTitleByLanguage(el),
        y: 1 * 100
    }))
    const mobileScreen = useMediaQuery("(max-width: 768px)")

    const sample_options = {
        credits: { enabled: false },
        chart: {
            type: 'variablepie'
        },
        accessibility: {
            enabled: false
        },
        title: {
            text: undefined
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> ' +
                '{point.name}</b><br/>' + '<b>{point.z}</b><br/>'
        },
        plotOptions: {

        },
        series: [{
            minPointSize: 10,
            innerSize: '20%',
            zMin: 132,
            name: 'name',
            dataLabels: {
                enabled: mobileScreen ? false : true
            },
            borderRadius: 5,
            data: values,
            colors: diagramColors
        }]
    }


    return (
        <Grid align="center" px={{ base: 12, sm: 32 }}>
            <Grid.Col mt={{ base: -120, lg: 0 }} span={{ base: 12, lg: 7 }}>
                <HighchartsReact
                    allowChartUpdate={true}
                    options={sample_options}
                    highcharts={Highcharts} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 5 }}>
                {data?.diagramFields?.map(item => (
                    <Grid
                        key={item.id}
                        align="center" justify="space-between"
                        w="100%"
                        maw={1200}
                    >
                        {i18n.language === "ru" ?
                            <Grid.Col span={12}>
                                <Grid align="center">
                                    <Grid.Col span={{ base: 8, md: 10 }}>
                                        <Title fz={{ base: 14, sm: 16, md: 18 }} c="secondary" lh={1}
                                            order={4} fw="400">
                                            {getTitleByLanguage(item)}
                                        </Title>
                                    </Grid.Col>
                                    <Grid.Col span={2}>
                                        <Title order={3} fw="400" c="primaryDark">
                                            {item.fieldValue?.toLocaleString()}
                                        </Title>
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col> :
                            <Grid.Col span={12}>
                                <Grid align="center">
                                    <Grid.Col span={{ base: 4, sm: 2 }}>
                                        <Title order={3} fw="400" c="primaryDark">
                                            {item.fieldValue?.toLocaleString()}
                                        </Title>
                                    </Grid.Col>
                                    <Grid.Col span={{ base: 8, sm: 10 }}>
                                        <Title fz={{ base: 14, sm: 16, md: 18 }} c="secondary" lh={1}
                                            order={4} fw="400">
                                            {getTitleByLanguage(item)}
                                        </Title>
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>}
                    </Grid>
                ))}
            </Grid.Col>
        </Grid>
    )
}
