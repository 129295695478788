import { Center, Flex, Grid } from '@mantine/core'
import { useEffect, useState } from 'react'
import { IS_MINUS_ONE, diagramColors } from 'src/constants'
import styles from "../../styles.module.scss"
import { BasicLineProgressItem } from '../line-progress-item'
import { RingProgress } from '../ring'

type Props = {
    data: SearchDiagramResponseContentType
}

const sizez2 = [300, 260, 220, 180, 140, 100, 60];

export const ClassicItem = ({ data }: Props) => {
    const [focused, setFocused] = useState(IS_MINUS_ONE);
    const [maxValue, setMaxValue] = useState(0);

    useEffect(() => {
        let max = 0;
        data?.diagramFields?.forEach((el) => {
            const current = el.fieldValue;
            max = Math.max(max, current || 0);
        });
        setMaxValue(max);
    }, [data]);
    return (
        <Grid
            gutter={50}
            px={{ base: 12, sm: 32 }}
            grow
            key={data.id}
            align="center"
            justify="space-around"
            className={styles.statistic}
        >
            <Grid.Col
                span={{ base: 12, lg: 8 }}
                className={styles.progress}
            >
                {data?.diagramFields?.map((item, idx) =>
                    idx < 7 && (
                        <BasicLineProgressItem
                            index={idx}
                            focused={focused}
                            setFocused={setFocused}
                            className={styles.progress_item}
                            maxValue={maxValue}
                            value={item.fieldValue || 0}
                            key={item.id}
                            item={item}
                            color={diagramColors[idx]}
                        />
                    )
                )}
            </Grid.Col>
            <Grid.Col display={{ base: "none", md: "block" }}
                mb={{ base: 30, lg: 0 }}
                h={sizez2[0]}
                span={{ base: 12, lg: 3 }}
            >
                <Flex m={14} h="100%" justify="center" align="center">
                    <Center>
                        {data?.diagramFields?.map(
                            (el, idx) =>
                                idx < 7 && (
                                    <RingProgress
                                        focused={focused}
                                        className={styles.ring_item}
                                        key={el.id}
                                        maxValue={maxValue}
                                        item={el}
                                        value={el.fieldValue}
                                        color={diagramColors[idx]}
                                        size={sizez2[idx]}
                                        thickness={16}
                                    />
                                )
                        )}
                    </Center>
                </Flex>
            </Grid.Col>
        </Grid>
    )
}
