import { lazy } from "react";
import { JSX } from "react/jsx-runtime";
import { officesIds } from "./constants";
// const HomePage = lazy(() => import("./pages/open-part/home"));


const AddReference = lazy(() => import("./pages/close-part/reference/add-reference"))
const Reference = lazy(() => import("./pages/close-part/reference/reference"))
const EditReference = lazy(() => import("./pages/close-part/reference/edit-reference"))
const Appeal = lazy(() => import("./pages/close-part/appeals/appeal-table"))
const AppealView = lazy(() => import("./pages/close-part/appeals/appeal-view"))
const SignInPage = lazy(() => import("./pages/close-part/sign-in-page"))
const Profile = lazy(() => import("./pages/close-part/profile"))
const ProsecutorOffice = lazy(() => import("./pages/close-part/office/office"))
const OfficeView = lazy(() => import("./pages/close-part/office/office-view"))
const AddOffice = lazy(() => import("./pages/close-part/office/add-office"))
const EditOffice = lazy(() => import("./pages/close-part/office/edit-office"))
const CardInfo = lazy(() => import("./pages/close-part/cards-info/card-info-table"))
const CardInfoView = lazy(() => import("./pages/close-part/cards-info/card-info-view"))
const AddCardInfo = lazy(() => import("./pages/close-part/cards-info/add-card-info"))
const EditCardInfo = lazy(() => import("./pages/close-part/cards-info/edit-card-info"))
const Constitution = lazy(() => import("./pages/close-part/constitution/constitution-table"))
const ConstitutionView = lazy(() => import("./pages/close-part/constitution/constitution-view"))
const AddConstitution = lazy(() => import("./pages/close-part/constitution/add-constitution"))
const EditConstitution = lazy(() => import("./pages/close-part/constitution/edit-constitution"))
const Banner = lazy(() => import("./pages/close-part/banner/banner-table"))
const BannerView = lazy(() => import("./pages/close-part/banner/banner-view"))
const AddBanner = lazy(() => import("./pages/close-part/banner/add-banner"))
const EditBanner = lazy(() => import("./pages/close-part/banner/edit-banner"))
const News = lazy(() => import("./pages/close-part/news/news"))
const NewsView = lazy(() => import("./pages/close-part/news/news-view"))
const AddNews = lazy(() => import("./pages/close-part/news/add-news"))
const EditNews = lazy(() => import("./pages/close-part/news/edit-news"))
const Map = lazy(() => import("./pages/close-part/map/map"))
const MapView = lazy(() => import("./pages/close-part/map/map-view"))
const AddMap = lazy(() => import("./pages/close-part/map/add-map"))
const EditMap = lazy(() => import("./pages/close-part/map/edit-map"))
const InfoBlock = lazy(() => import("./pages/close-part/info-block/info-block"))
const InfoBlockView = lazy(() => import("./pages/close-part/info-block/info-block-view"))
const AddInfoBlock = lazy(() => import("./pages/close-part/info-block/add-info-block"))
const EditInfoBlock = lazy(() => import("./pages/close-part/info-block/edit-info-block"))
const Page = lazy(() => import("./pages/close-part/page/page"))
const AddPage = lazy(() => import("./pages/close-part/page/add-page"))
const EditPage = lazy(() => import("./pages/close-part/page/edit-page"))
const Diagram = lazy(() => import("./pages/close-part/diagram/diagram"))
const DiagramView = lazy(() => import("./pages/close-part/diagram/diagram-view"))
const AddDiagram = lazy(() => import("./pages/close-part/diagram/add-diagram"))
const EditDiagram = lazy(() => import("./pages/close-part/diagram/edit-diagram"))
const DiagramCategory = lazy(() => import("./pages/close-part/diagram/category/diagram-category"))
const AddDiagramCategory = lazy(() => import("./pages/close-part/diagram/category/add-category"))
const EditDiagramCategory = lazy(() => import("./pages/close-part/diagram/category/edit-category"))
const InterCoopFile = lazy(() => import("./pages/close-part/inter-coop-file/inter-coop-file"))
const AddInterCoopFile = lazy(() => import("./pages/close-part/inter-coop-file/add-inter-coop-file"))
const EditInterCoopFile = lazy(() => import("./pages/close-part/inter-coop-file/edit-inter-coop-file"))
const PageListAccordion = lazy(() => import("./pages/close-part/page-list-accordion/page-list-accordion"))
const AddPageListAccordion = lazy(() => import("./pages/close-part/page-list-accordion/add-page-list-accordion"))
const EditPageListAccordion = lazy(() => import("./pages/close-part/page-list-accordion/edit-page-list-accordion"))

const HomePage = lazy(() => import("./pages/open-part/home"))
const NotFoundPage = lazy(() => import("./pages/404"))
const AboutPage = lazy(() => import("./pages/open-part/about/about"))
const AppealPage = lazy(() => import("./pages/open-part/appeal"))
const GosSymbolsPage = lazy(() => import("./pages/open-part/gos-symbols"))
const StatisticPage = lazy(() => import("./pages/open-part/statistic"))
const PersonnelPage = lazy(() => import("./pages/open-part/personnel"))
const LawsPage = lazy(() => import("./pages/open-part/laws"))
const ContactsPage = lazy(() => import("./pages/open-part/contacts"))
const StructurePage = lazy(() => import("./pages/open-part/about/structure"))
const InternationlCooperationPage = lazy(() => import("./pages/open-part/international-cooperation"))
const NewsPage = lazy(() => import("./pages/open-part/news"))
const NewsDetail = lazy(() => import("./pages/open-part/news/news-detail"))
const NewsDetail100years = lazy(() => import("./pages/open-part/news/news-detail/100years-news"))
const DocumentsPage = lazy(() => import("./pages/open-part/documents"))
const DocumentPage = lazy(() => import("./pages/open-part/personnel/documents"))
const ManagementPage = lazy(() => import("./pages/open-part/about/management"))
const ProtectBussiness = lazy(() => import("./pages/open-part/protect-bussiness"))
const AboutJournal = lazy(() => import("./pages/open-part/about/journal"))
const AboutAddressesPage = lazy(() => import("./pages/open-part/about/addresses"))
const PressServicePage = lazy(() => import("./pages/open-part/press-service"))
const ProsecutorSymbolPage = lazy(() => import("./pages/open-part/about/prosecutor-symbol"))
const DynamicPage = lazy(() => import("./pages/open-part/dynamic-page/dynamic-page"))
const DetailDynamicPage = lazy(() => import("./pages/open-part/dynamic-page/detail-page"))
const Prosecutor100Years = lazy(() => import("./pages/open-part/prosecutor-100-year"))
export interface RouteData {
    path: string;
    page: JSX.Element;
    public: boolean;
    child?: ReadonlyArray<RouteData>;
    children?: ReadonlyArray<RouteData>;
    isChild?: boolean;
}

export const routes: RouteData[] = [
    {
        path: "/",
        page: <HomePage />,
        public: true,
    },
    {
        path: "/allnews",
        page: <NewsPage />,
        public: true,
    },
    {
        path: "/allnews/:newsId",
        page: <NewsDetail />,
        public: true,
    },
    {
        path: "/100yearsnews/:newsId",
        page: <NewsDetail100years />,
        public: true,
    },
    {
        path: "/military-news",
        page: <NewsPage />,
        public: true,
    },
    {
        path: "/nadzor-news",
        page: <NewsPage />,
        public: true,
    },
    {
        path: "/transport-news",
        page: <NewsPage />,
        public: true,
    },

    {
        path: "/narynnews",
        page: <NewsPage />,
        public: true,
    },
    {
        path: "/talasnews",
        page: <NewsPage />,
        public: true,
    },
    {
        path: "/issykkylnews",
        page: <NewsPage />,
        public: true,
    },
    {
        path: "/100-year-prosecutor",
        page: <Prosecutor100Years />,
        public: true,
    },
    {
        path: "/oshnews",
        page: <NewsPage />,
        public: true,
    },
    {
        path: "/oshcitynews",
        page: <NewsPage />,
        public: true,
    },
    {
        path: "/batkennews",
        page: <NewsPage />,
        public: true,
    },

    {
        path: "/history",
        page: <AboutPage />,
        public: true,
    },
    {
        path: "/history/:page",
        page: <AboutPage />,
        public: true,
    },
    {
        path: "/about",
        page: <AboutPage />,
        public: true,
    },
    {
        path: "/managers",
        page: <ManagementPage />,
        public: true,
    },
    {
        path: "/managers/:id",
        page: <ManagementPage />,
        public: true,
    },
    {
        path: "/addresses",
        page: <AboutAddressesPage prosecutor={officesIds.managers} />,
        public: true,
    },
    {
        path: "/addresses/military-prokuratura",
        page: (
            <AboutAddressesPage prosecutor={officesIds.militaryProkuratura} />
        ),
        public: true,
    },
    {
        path: "/addresses/organ-prokuratura",
        page: <AboutAddressesPage prosecutor={officesIds.organProkuratura} />,
        public: true,
    },
    {
        path: "/addresses/transport-prokuratura",
        page: (
            <AboutAddressesPage prosecutor={officesIds.transportProkuratura} />
        ),
        public: true,
    },
    {
        path: "/addresses/manage-prokuratura",
        page: <AboutAddressesPage prosecutor={officesIds.organProkuratura} />,
        public: true,
    },
    {
        path: "/structure",
        page: <StructurePage />,
        public: true,
    },
    {
        path: "/magazine",
        page: <AboutJournal />,
        public: true,
    },
    {
        path: "/about/symbols",
        page: <GosSymbolsPage />,
        public: true,
    },
    {
        path: "/about/prosecutor-symbol",
        page: <ProsecutorSymbolPage />,
        public: true,
    },

    {
        path: "/reception",
        page: <AppealPage />,
        public: true,
    },

    {
        path: "/reception/internet-reception",
        page: <AppealPage />,
        public: true,
    },
    {
        path: "/reception/contacts-reception",
        page: <AppealPage />,
        public: true,
    },
    {
        path: "/reception/graphic-reception",
        page: <AppealPage />,
        public: true,
    },
    {
        path: "/international-cooperation",
        page: <InternationlCooperationPage />,
        public: true,
    },

    {
        path: "/frames",
        page: <PersonnelPage />,
        public: true,
    },
    {
        path: "/frames/frames-list",
        page: <PersonnelPage />,
        public: true,
    },
    {
        path: "/frames/frames-prokuror",
        page: <PersonnelPage />,
        public: true,
    },
    {
        path: "/frames/frames-reserv",
        page: <PersonnelPage />,
        public: true,
    },
    {
        path: "/frames/frames-helper",
        page: <PersonnelPage />,
        public: true,
    },
    {
        path: "/frames/frames-pensiya",
        page: <PersonnelPage />,
        public: true,
    },

    {
        path: "/document-page",
        page: <DocumentsPage />,
        public: true,
    },
    {
        path: "/position/:id",
        page: <DynamicPage />,
        public: true,
    },
    {
        path: "/position/:id/:detailId",
        page: <DetailDynamicPage />,
        public: true,
    },
    {
        path: "/press-service",
        page: <PressServicePage />,
        public: true,
    },
    {
        path: "/statistics",
        page: <StatisticPage />,
        public: true,
    },
    {
        path: "/frames/:sectionId",
        page: <DocumentPage />,
        public: true,
    },
    {
        path: "/contact",
        page: <ContactsPage />,
        public: true,
    },
    {
        path: "/security",
        page: <ProtectBussiness />,
        public: true,
    },
    {
        path: "/constitutions1993/:sectionId",
        page: <LawsPage yearId={1993} />,
        public: true,
    },
    {
        path: "/constitutions1993",
        page: <LawsPage yearId={1993} />,
        public: true,
    },
    {
        path: "/constitutions2010/:sectionId",
        page: <LawsPage yearId={2010} />,
        public: true,
    },
    {
        path: "/constitutions2010",
        page: <LawsPage yearId={2010} />,
        public: true,
    },
    {
        path: "/constitutions2017/:sectionId",
        page: <LawsPage yearId={2017} />,
        public: true,
    },
    {
        path: "/constitutions2017",
        page: <LawsPage yearId={2017} />,
        public: true,
    },
    {
        path: "/constitutions2021/:sectionId",
        page: <LawsPage yearId={2021} />,
        public: true,
    },
    {
        path: "/constitutions2021",
        page: <LawsPage yearId={2021} />,
        public: true,
    },
    {
        path: "/constitutions",
        page: <LawsPage yearId={2021} />,
        public: true,
    },
    {
        path: "/online_appeal",
        page: <Appeal />,
        public: false,
    },
    {
        path: "/online_appeal/:appealId",
        page: <AppealView />,
        public: false,
    },
    {
        path: "/prosecutor_office",
        page: <ProsecutorOffice />,
        public: false,
    },
    {
        path: "/prosecutor_office/:officeId",
        page: <OfficeView />,
        public: false,
    },
    {
        path: "/prosecutor_office/add",
        page: <AddOffice />,
        public: false,
    },
    {
        path: "/prosecutor_office/:officeId/edit",
        page: <EditOffice />,
        public: false,
    },
    {
        path: "/card_info",
        page: <CardInfo />,
        public: false,
    },
    {
        path: "/card_info/:cardId",
        page: <CardInfoView />,
        public: false,
    },
    {
        path: "/card_info/add",
        page: <AddCardInfo />,
        public: false,
    },
    {
        path: "/card_info/:cardId/edit",
        page: <EditCardInfo />,
        public: false,
    },
    {
        path: "/banner",
        page: <Banner />,
        public: false,
    },
    {
        path: "/banner/add-banner",
        page: <AddBanner />,
        public: false,
    },
    {
        path: "/banner/:bannerId/edit-banner",
        page: <EditBanner />,
        public: false,
    },
    {
        path: "/banner/:bannerId",
        page: <BannerView />,
        public: false,
    },
    {
        path: "/page_list_accordion",
        page: <PageListAccordion />,
        public: false,
    },
    {
        path: "/page_list_accordion/add-page_list_accordion",
        page: <AddPageListAccordion />,
        public: false,
    },
    {
        path: "/page_list_accordion/:pageAccordionId/edit-page_list_accordion",
        page: <EditPageListAccordion />,
        public: false,
    },
    {
        path: "/sign-in",
        page: <SignInPage />,
        public: true,
    },
    {
        path: "/profile",
        page: <Profile />,
        public: false,
    },

    {
        path: "/news",
        page: <News />,
        public: false,
    },

    {
        path: "/news/:newsId",
        page: <NewsView />,
        public: false,
    },
    {
        path: "/news/add-news",
        page: <AddNews />,
        public: false,
    },
    {
        path: "/news/:newsId/edit-news",
        page: <EditNews />,
        public: false,
    },
    {
        path: "/map_point",
        page: <Map />,
        public: false,
    },
    {
        path: "/map_point/:mapId",
        page: <MapView />,
        public: false,
    },
    {
        path: "/map_point/add-map_point",
        page: <AddMap />,
        public: false,
    },
    {
        path: "/map_point/:mapId/edit-map_point",
        page: <EditMap />,
        public: false,
    },
    {
        path: "/reference",
        page: <Reference />,
        public: false,
    },
    {
        path: "/reference_list",
        page: <Reference />,
        public: false,
    },
    {
        path: "/reference/add-reference",
        page: <AddReference />,
        public: false,
    },
    {
        path: "/reference/:referenceId/edit-reference",
        page: <EditReference />,
        public: false,
    },
    {
        path: "/info_block",
        page: <InfoBlock />,
        public: false,
    },
    {
        path: "/info_block/:infoBlockId",
        page: <InfoBlockView />,
        public: false,
    },
    {
        path: "/info_block/add-info_block",
        page: <AddInfoBlock />,
        public: false,
    },
    {
        path: "/info_block/:infoBlockId/edit-info_block",
        page: <EditInfoBlock />,
        public: false,
    },
    {
        path: "/constitution",
        page: <Constitution />,
        public: false,
    },
    {
        path: "/constitution/:constId",
        page: <ConstitutionView />,
        public: false,
    },
    {
        path: "/constitution/add-constitution",
        page: <AddConstitution />,
        public: false,
    },
    {
        path: "/constitution/:constId/edit-constitution",
        page: <EditConstitution />,
        public: false,
    },
    {
        path: "/page",
        page: <Page />,
        public: false,
    },
    {
        path: "/page/add-page",
        page: <AddPage />,
        public: false,
    },
    {
        path: "/page/:pageId/edit-page",
        page: <EditPage />,
        public: false,
    },
    {
        path: "/diagram",
        page: <Diagram />,
        public: false,
    },
    {
        path: "/diagram/add-diagram",
        page: <AddDiagram />,
        public: false,
    },
    {
        path: "/diagram/:diagramId/edit-diagram",
        page: <EditDiagram />,
        public: false,
    },
    {
        path: "/diagram/:diagramId",
        page: <DiagramView />,
        public: false,
    },
    {
        path: "/diagram-category",
        page: <DiagramCategory />,
        public: false,
    },
    {
        path: "/diagram/add-category",
        page: <AddDiagramCategory />,
        public: false,
    },
    {
        path: "/diagram/:diagramId/edit-category",
        page: <EditDiagramCategory />,
        public: false,
    },
    {
        path: "/inter_coop_file",
        page: <InterCoopFile />,
        public: false,
    },
    {
        path: "/inter_coop_file/add-inter_coop_file",
        page: <AddInterCoopFile />,
        public: false,
    },
    {
        path: "/inter_coop_file/:interCoopFileId/edit-inter_coop_file",
        page: <EditInterCoopFile />,
        public: false,
    },

    {
        path: "/not-found",
        page: <NotFoundPage />,
        public: true,
    },
];
