import { Grid, Title } from "@mantine/core";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { diagramColors } from "src/constants";
import { getTitleByLanguage } from "src/locales";

type Props = {
    data: SearchDiagramResponseContentType
}
export const VerticalItem = ({ data }: Props) => {
    const options = {
        credits: { enabled: false },
        chart: {
            type: 'column',
        },
        title: {
            text: undefined,
        },
        xAxis: {
            data: [""]
        },
        yAxis: {
            min: 0,
            title: {
                text: undefined
            }
        },
        tooltip: {
            valueSuffix: ''
        },
        legend: {
            enabled: false
        },
        dataLabels: {
            enabled: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.1,
                borderWidth: 0,
            }
        },
        accessibility: {
            enabled: false
        },
        series: data?.diagramFields?.map((el, index) => index < 7 && ({
            name: getTitleByLanguage(el),
            color: diagramColors[index],
            data: [el.fieldValue],
            dataLabels: [{
                enabled: true,
                inside: true,
                style: {
                    fontSize: "16px",
                }
            }]
        })),
    }


    return (
        <Grid align="center" px={{ base: 12, sm: 32 }}>
            <Grid.Col span={{ base: 12, lg: 6 }}>
                {data?.diagramFields?.map((item, index) => index < 7 && (
                    <Grid key={item.id} align='center' w="100%" justify="space-between">
                        <Grid.Col span={2}>
                            <Title order={3} fw="400" c="primaryDark">
                                {item.fieldValue?.toLocaleString()}
                            </Title>
                        </Grid.Col>
                        <Grid.Col span={10}>
                            <Title fz={{ base: 14, md: 18 }} c="secondary" lh={1} order={4} fw="400">
                                {getTitleByLanguage(item)}
                            </Title>
                        </Grid.Col>
                    </Grid>
                ))}
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 6 }}>
                <HighchartsReact
                    allowChartUpdate={true}
                    options={options}
                    highcharts={Highcharts} />
            </Grid.Col>
        </Grid>
    )
}
